<script>
	import {getLastOrder, getOrderById} from "../../functions/api/payment";
	import OrderHistoryCart from '../../components/order-history-cart';
	import MapLink from "../../components/map-link";
	import {getBuffetById} from "../../functions/api/content";

	export default {
		components: { MapLink, OrderHistoryCart },
		data() {
			return {
				isLoading: null,
				timeout: null,
				statusTypes: ['orderPayed', 'orderPreparing', 'orderReady', 'orderTaken'],
				orderId: this.$route.params.orderId,
				order: { cart: { items: [] } },
				buffet: null
			}
		},
		created() {
			if(!this.orderId) {
				getLastOrder(this.$store.state.mvmId).then(order => {
					this.orderId = order.id;
					if(!this.orderId) {
						this.$router.replace('/buffet-subpage');
					}
					this.init();
				});
			} else {
				this.init();
			}
		},
		destroyed() {
			clearTimeout(this.timeout);
		},
		methods: {
			init() {
				this.isLoading = true;
				this.startUpdating()
					.then(() => {
						this.refreshBuffet();
					})
			},
			startUpdating() {
				return this.refreshOrder()
					.then(() => {
						if(this.order.status === 'orderTaken') {
							clearTimeout(this.timeout);
							return;
						}
						this.timeout = setTimeout(() => {
							this.startUpdating();
						}, 4000);
					}).catch(err => {
						console.error(err);
						clearTimeout(this.timeout);
					}).then(() => this.isLoading = false);
			},
			refreshOrder() {
				return getOrderById(this.$store.state.userToken, this.orderId)
					.then(res => this.order = res);
			},
			refreshBuffet() {
				return getBuffetById(this.order.cart.buffetId)
					.then(buffet => this.buffet = buffet);
			},
			isStatusDone(statusIndex) {
				return statusIndex <= this.statusTypes.indexOf(this.order.status);
			}
		}
	}
</script>
<template>
	<div class="container" v-loading="isLoading === null || isLoading">
		<template v-if="isLoading === false">
			<section class="header">
				<h1>{{$t(order.id ? $t('order-progress-status-' + order.status) : 'title-last-order')}}</h1>
				<div v-if="order.date">
					{{$d(order.date * 1000, 'date')}} {{$d(order.date * 1000, 'time')}}
				</div>
			</section>
			<section v-if="!order.id" class="placeholder">
				<p>{{$t('order-history-placeholder')}}</p>
			</section>
			<template v-else>
				<section>
					<p>
						{{$t('order-desc-' + (
							order.status === 'orderCanceled' || order.status === 'orderInitiated' ?
									'canceled' :
									'success'
						))}}
					</p>
					<h2>{{order.serial}}</h2>
				</section>
				<section class="gradient-bg">
					<div class="inner-box">
						<ul>
							<li
									v-for="(status, index) in statusTypes"
									:key="status"
									:class="{done: isStatusDone(index)}"
							>
								<span class="circle">{{index + 1}}.</span>
								<h2>{{$t('order-progress-status-' + status)}}</h2>
							</li>
						</ul>
					</div>
				</section>
				<section class="header">
					<template v-if="buffet">
						<h2>{{buffet.title}}</h2>
						<div>
							<map-link :location="buffet.location" :buffetName="buffet.title"></map-link>
						</div>
					</template>
				</section>
				<section>
					<!--div class="section-title">Rendelésem</div-->
					<h2>{{$t('orders-cart')}}</h2>
					<order-history-cart :cartItems="order.cart.items"></order-history-cart>
				</section>
			</template>
			<section class="row">
				<router-link to="/buffet-subpage">
					<button class="gold-button">
						{{$t('new-order')}}
					</button>
				</router-link>
			</section>
		</template>
	</div>
</template>
<style scoped>
	#order-progress,
	#order-progress-without-id {
		min-height: calc(100vh - 140px);
		display: flex;
		flex-direction: column;
	}
	h1, h2 {
		margin-bottom: 8px;
	}
	section {
		margin-bottom: 24px;
	}
	section.placeholder {
		flex: 1;
	}
	.order-history-cart {
		max-height: 180px;
		overflow: auto;
	}
	.gold-button {
		width: 100%;
	}
	.gradient-bg {
		background: linear-gradient(180deg, #073c1a 10%, #087839 50%, #02140a 90%);
		margin-left: -20px;
		margin-right: -20px;
		padding: 55px 20px;
		flex: 1;
		display: flex;
	}
	.gradient-bg .inner-box {
		padding: 30px 22px;
		border-radius: 4px;
		background: white;
		display: flex;
		width: 334px;
		margin: auto;
	}
	.gradient-bg .inner-box ul {
		margin: auto;
		padding: 0;
		display: inline-block;
	}
	.gradient-bg .inner-box ul li {
		list-style: none;
		line-height: 54px;
	}
	.gradient-bg .inner-box ul li:not(:last-of-type) {
		margin-bottom: 50px;
	}
	.circle {
		display: inline-block;
		width: 54px;
		height: 54px;
		border-radius: 50%;
		text-align: center;
		margin-right: 14px;
	}
	.gradient-bg .inner-box ul li:not(.done) {
		color: var(--disabled-color);
	}
	.gradient-bg .inner-box ul li:not(.active) .circle {
		border: 1px solid var(--disabled-color);
		width: 52px;
		height: 52px;
		color: var(--disabled-color);
	}
	.gradient-bg .inner-box ul li.done .circle {
		background-color: var(--theme-color-3);
		color: white;
	}
	.gradient-bg .inner-box ul li.done {
		color: var(--theme-color-3);
	}
	.gradient-bg .inner-box ul li h2 {
		display: inline-block;
		margin: 0;
	}
	@media screen and (max-width: 330px) {
		.circle {
			display: block;
			margin: auto;
		}
		.gradient-bg .inner-box ul li h2 {
			display: block;
			text-align: center;
		}
	}
</style>
